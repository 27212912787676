import { Container, Row, Col, Table } from 'react-bootstrap';
import './Finocchio.css';
import carciofo from '../../assets/finocchio.jpg';
import cassa from '../../assets/cassafinocchi.jpg';
import campo from '../../assets/pedanafinocchi.jpg';
import mazzo from '../../assets/finocchiotagliato.jpg';

const Finocchio = () => {
 
  const titles = {
    1009: "Introduzione",
    1023: "Valori nutrizionali",
    1024: "Conservazione",
    1025: "In conclusione"
    
  };
  const images = {
    1009: carciofo,
    1023: cassa,
    1024: campo,
    1025: mazzo
  };

 const articoli = {
  1009: {contenuto:`
  Il finocchio è un ortaggio dalla struttura croccante e dal sapore dolciastro che lo rende un ingrediente prediletto in molti piatti mediterranei. Conosciuto scientificamente come Foeniculum vulgare, il finocchio è apprezzato non solo per le sue qualità culinarie ma anche per i suoi numerosi benefici per la salute. In questo articolo, esploreremo in dettaglio le caratteristiche del finocchio, le sue proprietà nutrizionali e i consigli per conservarlo correttamente.
  Descrizione del Prodotto
  Il finocchio è una pianta erbacea perenne che appartiene alla famiglia delle Apiaceae, la stessa del prezzemolo e della carota. Si distingue per il suo bulbo bianco o verde pallido, le lunghe fronde verdi simili a quelle dell'aneto e un aroma distintivo che ricorda quello dell'anice. Il bulbo, croccante e dolciastro, è la parte più consumata, ma anche le fronde e i semi del finocchio sono commestibili e utilizzati per arricchire insalate, condimenti e infusi.
  ` },
  1023: {contenuto:`
  Il finocchio è particolarmente ricco di vitamina C, essenziale per il sistema immunitario e l'assorbimento del ferro. Offre anche una buona dose di fibre alimentari, che promuovono la salute intestinale e contribuiscono alla sensazione di sazietà. Altri nutrienti importanti presenti nel finocchio includono il potassio, che aiuta a regolare la pressione sanguigna, e il ferro, necessario per il trasporto dell'ossigeno nel sangue. Inoltre, il finocchio contiene antiossidanti come la quercetina e la rutina, che proteggono le cellule dai danni dei radicali liberi.
  ` },
  1024: {contenuto:`Consigli sulla Conservazione.
  Per conservare il finocchio nella maniera migliore e mantenere le sue qualità nutritive, è consigliabile seguire alcuni semplici passaggi:
  Pulizia: Pulire il finocchio rimuovendo le eventuali foglie esterne danneggiate e tagliando via la base del bulbo.
  Conservazione in frigorifero: Avvolgere il finocchio in un panno umido o in una busta di plastica forata e riporlo nel cassetto delle verdure del frigorifero. Questo metodo aiuta a mantenere l'umidità necessaria per preservare la freschezza del finocchio.
  Durata: Il finocchio può essere conservato in frigorifero per circa una settimana. È importante controllare periodicamente che non si disidrati o inizi a marcire.
  Uso delle fronde: Le fronde possono essere tritate e utilizzate fresche come erba aromatica o conservate in un bicchiere d'acqua in frigorifero, simile al modo in cui si conservano i bouquet di erbe fresche.
  ` },
  1025: {contenuto:`In conclusione, il finocchio è un ortaggio versatile che arricchisce di gusto e nutrimento le tavole di chi lo consuma. Con le dovute precauzioni nella conservazione, è possibile godere a lungo dei suoi benefici, incorporandolo in una varietà di piatti salutari e gustosi.` }
 }

 const coltura = {
  Nome: "Finocchio",
  NomeScientifico: "Foeniculum vulgare",
  Famiglia: "Apiaceae",
  BrixMedio: "Na",
  Calibro: "0,3-0,5kg",
  Origine: "Lazio, Italia",
  Produzione: "Dicembre-Marzo",
  Confezionamento: "Casse 40x60: 20x, 22x"
};
  return (
    <Container fluid className="carciofo p-0">
      <div className='photoHolder5'><h1 className='text-center'>FINOCCHIO</h1></div>
      <div className='content-section'>
        {Object.entries(articoli).map(([id, articolo], index) => (
          <Row key={id} className="justify-content-center m-0 article-row align-items-center">
            {index % 2 === 0 ? (
              <>
                <Col md={6} className="text-content animated-left">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
                <Col md={6} className="image-content animated-right">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="image-content animated-left">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
                <Col md={6} className="text-content animated-right">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
              </>
            )}
          </Row>
        ))}
        <Row className="justify-content-center m-0">
          <Col md={12} className="table-responsive">
            <h2 className='h2iamo'>Alcuni dati</h2>
            <Table striped bordered hover className="coltura-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Nome Scientifico</th>
                  <th>Famiglia</th>
                  <th>Brix Medio</th>
                  <th>Calibro</th>
                  <th>Origine</th>
                  <th>Produzione</th>
                  <th>Confezionamento</th>
                </tr>
              </thead>
              <tbody>
                
                  <tr>
                    <td>{coltura.Nome}</td>
                    <td>{coltura.NomeScientifico}</td>
                    <td>{coltura.Famiglia}</td>
                    <td>{coltura.BrixMedio}</td>
                    <td>{coltura.Calibro}</td>
                    <td>{coltura.Origine}</td>
                    <td>{coltura.Produzione}</td>
                    <td>{coltura.Confezionamento}</td>
                  </tr>
                
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Finocchio;
