import { Container, Row, Col, Table } from 'react-bootstrap';
import './Brassiche.css';
import carciofo from '../../assets/cassabroccolo.jpg';
import cassa from '../../assets/broccolo.jpg';
import campo from '../../assets/Cavolfiorebianco.webp';
import mazzo from '../../assets/cestomisto.jpg';
import bello from '../../assets/todis.webp';

const Brassiche = () => {

  const titles = {
    1008: "Introduzione",
    1019: "Broccolo romanesco",
    1020: "Cavolfiore bianco",
    1021: "Valori nutrizionali",
    1022: "Conservazione"
  };
  const images = {
    1008: carciofo,
    1019: cassa,
    1020: campo,
    1021: mazzo,
    1022: bello
  };
  const articoli = {
    1008: {contenuto:`
    Introduzione
    Tra le verdure più apprezzate per le loro qualità nutritive e la versatilità in cucina, i broccoli romaneschi e i cavolfiori bianchi spiccano per il loro gusto delicato e le molteplici applicazioni culinarie. Questi ortaggi, appartenenti alla famiglia delle Brassicaceae, non solo arricchiscono i piatti con il loro colore vivace e la loro consistenza croccante, ma offrono anche una serie di benefici per la salute grazie al loro ricco profilo nutrizionale.
    ` },
    1019: {contenuto:`
    I broccoli romaneschi sono facilmente riconoscibili per le loro infiorescenze verdi e il gusto leggermente più amaro rispetto ai cavolfiori tradizionali. Questo tipo di broccolo si caratterizza per le cime fiorite che sono tenere e piene di sapore. Coltivati principalmente nelle regioni del centro Italia, i broccoli romaneschi sono una presenza fissa nelle cucine locali, dove vengono utilizzati per arricchire pasta, risotti e contorni.` },
    1020: {contenuto:`
    Il cavolfiore bianco è noto per la sua testa compatta, di colore bianco candido, protetta da foglie robuste e verdi. La sua texture è più morbida rispetto a quella del broccolo romanesco, e il sapore è delicatamente dolce. Il cavolfiore è estremamente versatile in cucina, utilizzato per preparare vellutate, gratinati, fritti, e anche crudi in insalate.` },
    1021: {contenuto:`
    Broccoli Romaneschi:
    Ricchi di vitamina C, vitamina K, fibra alimentare, e una serie di minerali tra cui potassio, ferro e calcio, i broccoli romaneschi sono un ottimo alleato per la salute del sistema immunitario e la prevenzione di malattie croniche. Inoltre, la presenza di composti antiossidanti e antinfiammatori, come il sulforafano, contribuisce alla lotta contro le cellule cancerogene.
    Cavolfiori Bianchi:
    I cavolfiori bianchi sono una fonte eccellente di vitamina C, che supporta il sistema immunitario e la produzione di collagene. Offrono inoltre un buon apporto di fibre, che aiutano a mantenere la salute digestiva, e sono ricchi di antiossidanti che combattono i radicali liberi, riducendo il rischio di malattie come il cancro e l'invecchiamento precoce.` },
    1022: {contenuto:`
    Per mantenere intatte le proprietà nutritive e organolettiche dei broccoli romaneschi e dei cavolfiori bianchi, è essenziale seguirne correttamente la conservazione. Entrambi dovrebbero essere conservati in frigorifero, idealmente in un cassetto per verdure. È consigliabile avvolgere i cavolfiori in un panno umido o in una pellicola perforata per mantenere l'umidità, evitando così che si disidratino. I broccoli, d'altra parte, beneficiano di una conservazione leggermente aperta per permettere una circolazione d'aria che ne impedisca il deterioramento. Per entrambi, l'ideale sarebbe consumarli entro 5-7 giorni dall'acquisto per godere della loro freschezza e delle loro qualità nutritive al massimo.
    In conclusione, i broccoli romaneschi e i cavolfiori bianchi non solo arricchiscono la tavola con gusto e colore, ma offrono anche benefici significativi per la salute. Con le giuste tecniche di conservazione, è possibile godere al massimo delle loro qualità nutritive e saporite.` },
  }
  const coltura = [{
    Nome: "Broccolo Romanesco",
    NomeScientifico: "Brassica oleracea",
    Famiglia: "Brassicaceae",
    BrixMedio: "Na",
    Calibro: "0.8-1,2kg",
    Origine: "Lazio, Italia",
    Produzione: "Novembre-Marzo",
    Confezionamento: "Casse 40x60: x6, x7, x8"
  },
  {
    Nome: "Cavolfiore Bianco",
    NomeScientifico: "Brassica oleracea",
    Famiglia: "Brassicaceae",
    BrixMedio: "Na",
    Calibro: "1-1,5kg",
    Origine: "Lazio, Italia",
    Produzione: "Novembre-Marzo",
    Confezionamento: "Casse 40x60: x6, x7, x8"
  }
]
  


  return (
    <Container fluid className="carciofo p-0">
      <div className='photoHolder4'><h1 className='text-center'>BRASSICHE</h1></div>
      <div className='content-section'>
        {Object.entries(articoli).map(([id, articolo], index) => (
          <Row key={id} className="justify-content-center m-0 article-row align-items-center">
            {index % 2 === 0 ? (
              <>
                <Col md={6} className="text-content animated-left">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
                <Col md={6} className="image-content animated-right">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="image-content animated-left">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
                <Col md={6} className="text-content animated-right">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
              </>
            )}
          </Row>
        ))}
        <Row className="justify-content-center m-0">
          <Col md={12} className="table-responsive">
            <h2 className='h2iamo'>Alcuni dati</h2>
            <Table striped bordered hover className="coltura-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Nome Scientifico</th>
                  <th>Famiglia</th>
                  <th>Brix Medio</th>
                  <th>Calibro</th>
                  <th>Origine</th>
                  <th>Produzione</th>
                  <th>Confezionamento</th>
                </tr>
              </thead>
              <tbody>
                {coltura.map(coltura => (
                  <tr key={coltura.Nome}>
                    <td>{coltura.Nome}</td>
                    <td>{coltura.NomeScientifico}</td>
                    <td>{coltura.Famiglia}</td>
                    <td>{coltura.BrixMedio}</td>
                    <td>{coltura.Calibro}</td>
                    <td>{coltura.Origine}</td>
                    <td>{coltura.Produzione}</td>
                    <td>{coltura.Confezionamento}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Brassiche;
