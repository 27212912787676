import { Container, Row, Col } from 'react-bootstrap';
import './ChiSiamo.css';
import img1 from '../../assets/chisiamoimg1.jpg';
import img2 from '../../assets/chisiamoimg2.jpg';

const ChiSiamo = () => {


  return (
    <Container fluid className="chi-siamo p-0">
      <div className='photoHolder1'><h1 className='text-center'>CHI SIAMO</h1></div>
  <div className='ciccio'>
        <Row className="justify-content-center m-0 article-row align-items-center">
          <Col md={6} className="text-content animated-left">
            <h2 className='h2iamo'>Un po' di noi...</h2>
            <p className='piamo'>L'Azienda Agricola Zuccante Marco, nata nel cuore del Lazio nel 1936, è un esempio eccellente di dedizione e passione nella ricerca della qualità ortofrutticola. La svolta avviene nel lontano 1989, decidendo di specializzarsi nell'orticultura, pone le fondamenta di quello che sarebbe diventato un vero e proprio baluardo per l'agricoltura di qualità.
            Da allora, l'impegno della famiglia Zuccante, giunta ormai alla quinta generazione, non ha mai smesso di crescere, portando avanti una tradizione di eccellenza. Ogni frutto e ogni ortaggio è il risultato di un lavoro attento, che inizia dalla selezione dei semi fino alla cura del prodotto finito. La qualità non è soltanto un obiettivo, ma un vero e proprio manifesto che si riflette nel sapore e nella freschezza dei nostri prodotti.</p>
          </Col>
          <Col md={6} className="image-content order-md-last animated-right">
            <div className="image-circle" style={{ backgroundImage: `url(${img1})` }}></div>
          </Col>
        </Row>
        <Row className="justify-content-center m-0 article-row align-items-center">
          <Col md={6} className="text-content order-md-2 animated-right">
            <h2 className='h2iamo'>...</h2>
            <p className='piamo'>Le tecniche produttive, sempre al passo con i tempi, hanno subìto un'evoluzione costante, e l'entrata nell'arena della grande distribuzione organizzata è la testimonianza di un'azienda che non solo ha saputo mantenere elevati standard di qualità, ma che guarda al futuro con la prospettiva di un crescendo di successi e riconoscimenti.
            L'Azienda Agricola Zuccante Marco oggi non è soltanto un nome, ma una garanzia di eccellenza e rispetto per la natura, che promette ai suoi consumatori non solo un prodotto buono, ma il frutto di una storia di dedizione e amore per la terra. Con lo sguardo rivolto verso un futuro sostenibile, la nostra azienda sogna e lavora per una prosperità che sia frutto di un equilibrio tra innovazione e tradizione.</p>
          </Col>
          <Col md={6} className="image-content animated-left">
            <div className="image-circle rotatethis1" style={{ backgroundImage: `url(${img2})` }}></div>
          </Col>
        </Row>
  </div>
    </Container>
  );
};

export default ChiSiamo;
