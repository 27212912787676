import { Container, Row, Col, Table } from 'react-bootstrap';
import './Melone.css';
import carciofo from '../../assets/melbins1.jpg';
import cassa from '../../assets/melcassa1.jpg';
import campo from '../../assets/melimp.jpg';

const Melone = () => {

  const titles = {
    1011: "Retato",
    1030: "Francesino",
    1031: "Polpa rossa"
    
  };
  const images = {
    1011: carciofo,
    1030: cassa,
    1031: campo
  };

  const articoli = {
    1011: {contenuto:`Il Melone Retato - La Perfezione Dura.
    Il melone retato è apprezzato per la sua pasta particolarmente dura che non solo conferisce una piacevole consistenza croccante ma permette anche una conservazione ottimale. Con un grado Brix che si colloca tra 13° e 15°, questi meloni promettono una dolcezza equilibrata, ideale per rinfrescare le calde giornate estive.
    Consigli sulla Conservazione
    Per mantenere la freschezza del melone retato, conservarlo in un luogo fresco e asciutto fino a quando non viene tagliato. Dopo il taglio, conservare in frigorifero coperto, dove può rimanere fresco e consumabile per 5-7 giorni.
` },
    1030: {contenuto:`Melone Francesino o Charentais - Delicatezza e Dolcezza Estrema.
    Il melone Francesino, conosciuto anche come Charentais, è celebre per la sua pasta morbida e succulenta. Questa varietà si distingue per un elevato grado Brix di 15°-17°, assicurando un'esperienza gustativa intensamente dolce.
    Cura nella Coltivazione
    La coltivazione del melone Francesino richiede un attento monitoraggio delle condizioni ambientali per ottimizzare il contenuto di zuccheri e la texture del frutto. Ogni passaggio, dalla semina alla raccolta, è attentamente gestito per garantire la migliore qualità possibile.
    Consigli sulla Conservazione
    Questi meloni dovrebbero essere consumati entro 4-6 giorni dalla raccolta per godere della loro massima freschezza e sapore. Una volta aperti, devono essere conservati in frigorifero e coperti per mantenere la loro integrità.
    ` },
    1031: {contenuto:`Melone a Polpa Rossa - Croccantezza e Longevità.
    Il melone a polpa rossa è noto per la sua straordinaria croccantezza e un grado Brix che oscilla tra 14°-16°. Questo melone non solo offre un gusto deliziosamente dolce ma è anche famoso per la sua lunga conservabilità di 7-9 giorni.
    Proprietà nutrizionali
    Il melone è un frutto estremamente nutriente e salutare. Ricco di vitamina C, contribuisce significativamente alla protezione delle cellule dallo stress ossidativo e sostiene la salute della pelle. Offre anche una buona quantità di vitamina A, essenziale per la vista e per il sistema immunitario. Inoltre, è una fonte di potassio, che aiuta a regolare la pressione sanguigna e supporta la funzione muscolare e nervosa. Il melone è anche idratante grazie al suo alto contenuto di acqua, e fornisce fibre che promuovono la salute digestiva. Con un basso contenuto calorico e un elevato contenuto di nutrienti, è un'aggiunta perfetta a una dieta equilibrata.` }
  }

  const colture = [{
    Nome: "Melone Retato",
    NomeScientifico: "Cucumis Melo",
    Famiglia: "Cucurbitaceae",
    BrixMedio: "14°",
    Calibro: "1-1,8kg",
    Origine: "Lazio, Italia",
    Produzione: "Maggio-Settembre",
    Confezionamento: "Casse 30x40: x4,x5,x6"
  }
    ,
    {
      Nome: "Melone Francesino",
      NomeScientifico: "Cucumis Melo",
      Famiglia: "Cucurbitaceae",
      BrixMedio: "17°",
      Calibro: "1-1,8kg",
      Origine: "Lazio, Italia",
      Produzione: "Maggio-Settembre",
      Confezionamento: "Casse 30x40: x4,x5,x6"
    },
    {
      Nome: "Melone polpa rossa",
      NomeScientifico: "Cucumis Melo",
      Famiglia: "Cucurbitaceae",
      BrixMedio: "16°",
      Calibro: "1-1,8kg",
      Origine: "Lazio, Italia",
      Produzione: "Maggio-Settembre",
      Confezionamento: "Casse 30x40: x4,x5,x6"
    }]


  return (
    <Container fluid className="carciofo p-0">
      <div className='photoHolder8'><h1 className='text-center'>MELONE</h1></div>
      <div className='content-section'>
        {Object.entries(articoli).map(([id, articolo], index) => (
          <Row key={id} className="justify-content-center m-0 article-row align-items-center">
            {index % 2 === 0 ? (
              <>
                <Col md={6} className="text-content animated-left">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
                <Col md={6} className="image-content animated-right">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="image-content animated-left">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
                <Col md={6} className="text-content animated-right">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
              </>
            )}
          </Row>
        ))}
        <Row className="justify-content-center m-0">
          <Col md={12} className="table-responsive">
            <h2 className='h2iamo'>Alcuni dati</h2>
            <Table striped bordered hover className="coltura-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Nome Scientifico</th>
                  <th>Famiglia</th>
                  <th>Brix Medio</th>
                  <th>Calibro</th>
                  <th>Origine</th>
                  <th>Produzione</th>
                  <th>Confezionamento</th>
                </tr>
              </thead>
              <tbody>
                {colture.map(coltura => (
                  <tr key={coltura.Nome}>
                    <td>{coltura.Nome}</td>
                    <td>{coltura.NomeScientifico}</td>
                    <td>{coltura.Famiglia}</td>
                    <td>{coltura.BrixMedio}</td>
                    <td>{coltura.Calibro}</td>
                    <td>{coltura.Origine}</td>
                    <td>{coltura.Produzione}</td>
                    <td>{coltura.Confezionamento}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Melone;
