import { Container, Row, Col, Table } from 'react-bootstrap';
import './Cocomero.css';
import carciofo from '../../assets/cocomero.jpg';
import cassa from '../../assets/binscoc.jpg';
import campo from '../../assets/Cocomero_copertina.jpg';
import mazzo from '../../assets/anguria-a-cubetti.jpg';
import mazzo1 from '../../assets/coc1.webp';

const Cocomero = () => {

  const titles = {
    1010: "Introduzione",
    1026: "Metodi colturali",
    1027: "Valori nutrizionali",
    1028: "Conservazione",
    1029: "In conclusione"
    
  };
  const images = {
    1010: carciofo,
    1026: cassa,
    1027: campo,
    1028: mazzo,
    1029: mazzo1
  };

  const articoli = {
    1010: {contenuto:`I Cocomeri di Palidoro: Dolcezza e Salute dal Cuore della Terra.
    Il cocomero, o anguria, è uno dei frutti più amati e rinfrescanti dell'estate. Originario dell'Africa, questo frutto è coltivato con successo in molte parti del mondo, ma trova una particolare vocazione nelle terre fertili di Palidoro, dove condizioni climatiche e tecniche agricole si combinano per produrre cocomeri eccezionalmente dolci e succosi.
    Descrizione del Prodotto e del Territorio
    I cocomeri sono noti per la loro croccantezza e il contenuto zuccherino elevato, che li rende particolarmente gustosi. La zona di Palidoro, caratterizzata da un terreno ricco e ben drenato, è ideale per la coltivazione del cocomero, grazie anche a un clima che alterna calde giornate soleggiate a notti fresche, favorendo lo sviluppo di frutti grandi e ricchi di acqua.
    ` },
    1026: {contenuto:`Gestione dello Sviluppo della Coltura.
    La coltivazione dei cocomeri nella nostra azienda segue metodi rigorosi che assicurano la massima qualità del prodotto. Dalla preparazione del terreno alla scelta delle varietà più adatte al suolo e al clima locali, ogni fase del processo è curata nei minimi dettagli. Particolare attenzione è rivolta alla gestione dell'irrigazione, essenziale per mantenere il giusto equilibrio di umidità che permette ai frutti di sviluppare la loro nota dolcezza.` },
    1027: {contenuto:`
    Il cocomero è composto per circa il 90% da acqua, il che lo rende un'eccellente fonte di idratazione durante i mesi caldi. È anche ricco di vitamina C, vitamine del gruppo B e antiossidanti come il licopene, che contribuisce alla salute del cuore e della pelle. Inoltre, il cocomero è una buona fonte di potassio, importante per la regolazione della pressione sanguigna, e contiene modesti livelli di fibre, utili per la salute digestiva.
    ` },
    1028: {contenuto:`Consigli sulla Conservazione.
    Per conservare al meglio i cocomeri e godere della loro freschezza il più a lungo possibile, è importante seguire alcuni accorgimenti:
    Temperatura: Conservare i cocomeri interi a temperatura ambiente fino al momento del taglio. Una volta aperti, i cocomeri dovrebbero essere coperti con pellicola trasparente e conservati in frigorifero.
    Consumo: Dopo il taglio, è consigliabile consumare il cocomero entro 3-4 giorni per assaporare il massimo della sua freschezza e delle sue proprietà nutrizionali.
    Taglio: Utilizzare sempre utensili puliti per tagliare il cocomero, per prevenire la contaminazione e prolungare la sua conservabilità.
    ` },
    1029: {contenuto:`In conclusione, i cocomeri di Palidoro offrono un mix perfetto di piacere e salute. Attraverso pratiche agricole sostenibili e un'attenzione meticolosa a ogni fase della loro coltivazione, questi frutti non solo deliziano il palato ma contribuiscono anche al benessere di chi li consuma.` }
  }

  const coltura = {
    Nome: "Anguria Sentinel",
    NomeScientifico: "Citrullus ianatus",
    Famiglia: "Cucurbitaceae",
    BrixMedio: "13°",
    Calibro: "12-20kg",
    Origine: "Lazio, Italia",
    Produzione: "Luglio-Agosto",
    Confezionamento: "Bins (sfusi)"
  };

  return (
    <Container fluid className="carciofo p-0">
      <div className='photoHolder6'><h1 className='text-center'>COCOMERO</h1></div>
      <div className='content-section'>
        {Object.entries(articoli).map(([id, articolo], index) => (
          <Row key={id} className="justify-content-center m-0 article-row align-items-center">
            {index % 2 === 0 ? (
              <>
                <Col md={6} className="text-content animated-left">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
                <Col md={6} className="image-content animated-right">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="image-content animated-left">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
                <Col md={6} className="text-content animated-right">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
              </>
            )}
          </Row>
        ))}
        <Row className="justify-content-center m-0">
          <Col md={12} className="table-responsive">
            <h2 className='h2iamo'>Alcuni dati</h2>
            <Table striped bordered hover className="coltura-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Nome Scientifico</th>
                  <th>Famiglia</th>
                  <th>Brix Medio</th>
                  <th>Calibro</th>
                  <th>Origine</th>
                  <th>Produzione</th>
                  <th>Confezionamento</th>
                </tr>
              </thead>
              <tbody>
                  <tr key={coltura.ColturaID}>
                    <td>{coltura.Nome}</td>
                    <td>{coltura.NomeScientifico}</td>
                    <td>{coltura.Famiglia}</td>
                    <td>{coltura.BrixMedio}</td>
                    <td>{coltura.Calibro}</td>
                    <td>{coltura.Origine}</td>
                    <td>{coltura.Produzione}</td>
                    <td>{coltura.Confezionamento}</td>
                  </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Cocomero;
