import { Container, Row, Col } from 'react-bootstrap';
import './Filiera.css'; 
import Filiera1 from '../../assets/FilieraImg1.jpg'
import Filiera2 from '../../assets/FilieraImg2.jpg'
import Filiera3 from '../../assets/FilieraImg3.jpg'

const Filiera = () => {


  return (
    <Container fluid className="filiera p-0">
      <div className='photoHolder2'><h1 className='text-center'>FILIERA</h1></div>
      <div className='content-section'>
        <Row className="justify-content-center m-0 article-row align-items-center">
          <Col md={6} className="text-content animated-left">
            <h2 className='section-title'>La nostra filiera</h2>
            <p className='section-content'>L'Azienda Agricola Zuccante Marco si distingue nel panorama ortofrutticolo per l'eccellente gestione della sua filiera produttiva, dalla scelta delle piantine fino alla conservazione finale del raccolto. Ogni fase è curata con rigore e dedizione, nel rispetto di pratiche agricole sostenibili.
            Selezione e Coltivazione:
            Il processo inizia con una scrupolosa selezione dei vivai più affidabili e delle varietà più promettenti, garantendo piantine robuste e adatte al terreno e al clima del Lazio. Questa attenzione alla genetica delle piante è il primo passo verso un prodotto finito di qualità superiore.
</p>
          </Col>
          <Col md={6} className="image-content animated-right">
            <div className="image-circle" style={{ backgroundImage: `url(${Filiera1})` }}></div>
          </Col>
        </Row>
        <Row className="justify-content-center m-0 article-row align-items-center">
          <Col md={6} className="text-content animated-left order-md-2">
            <p className='section-content'>Trapianto e Cura delle Piante:
            La fase di trapianto è pianificata con precisione per assicurare che ogni pianta abbia lo spazio e le risorse necessarie per svilupparsi al meglio. Il terreno, arricchito con concimi naturali, è preparato per accogliere le giovani piante e garantirne il corretto sviluppo.
            Concimazione e Difesa Integrata:
            L'azienda utilizza pratiche di concimazione mirate, preferendo prodotti naturali che arricchiscano il terreno senza danneggiarlo. La filosofia della difesa integrata permette di proteggere le coltivazioni dagli agenti patogeni riducendo al minimo l'utilizzo di prodotti chimici, in un equilibrio perfetto tra produttività e sostenibilità ambientale.
            </p>
          </Col>
          <Col md={6} className="image-content animated-right">
            <div className="image-circle rotatethis" style={{ backgroundImage: `url(${Filiera2})` }}></div>
          </Col>
        </Row>
        <Row className="justify-content-center m-0 article-row align-items-center">
          <Col md={6} className="text-content animated-left">
            <p className='section-content'>Raccolta e Conservazione:
            La raccolta è un momento cruciale: viene eseguita con la massima attenzione per non danneggiare i frutti e per garantire che vengano colti nel momento ottimale di maturazione. Subito dopo, il prodotto è immesso in una catena del freddo ben regolata, preservando la freschezza e le proprietà nutritive del raccolto fino al consumatore finale.
            Certificazioni e Riconoscimenti:
            Le pratiche sostenibili e l'alta qualità dei prodotti hanno permesso all'Azienda Agricola Zuccante Marco di ottenere importanti certificazioni, che attestano l'impegno verso un'agricoltura rispettosa dell'ambiente e orientata al benessere del consumatore.
            Nella nostra azienda la qualità è una promessa mantenuta in ogni dettaglio, un impegno che si rinnova giorno dopo giorno, nel rispetto di una tradizione agricola che guarda al futuro con la sicurezza di chi sa che solo la cura e la passione possono garantire i migliori frutti della terra.</p>
          </Col>
          <Col md={6} className="image-content animated-right">
            <div className="image-circle" style={{ backgroundImage: `url(${Filiera3})` }}></div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Filiera;
