import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigationbar from './components/nav/Navbar';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import '../src/styles/global.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ChiSiamo from './components/chisiamo/ChiSiamo';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Filiera from './components/filiera/Filiera';
import Contatti from './components/Contatti/Contatti';
import Carciofo from './components/carciofo/Carciofo';
import Brassiche from './components/brassiche/Brassiche';
import Finocchio from './components/finocchio/Finocchio';
import Cocomero from './components/cocomero/Cocomero';
import Melone from './components/melone/Melone';
import Galleria from './components/galleria/Galleria';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';



function App() {


  return (
    <Router>
      <Navigationbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chisiamo" element={<ChiSiamo />} />
        <Route path="/filiera" element={<Filiera />} />
        <Route path="/contatti" element={<Contatti />} />
        <Route path="/carciofo" element={<Carciofo />} />
        <Route path="/brassicaceae" element={<Brassiche />} />
        <Route path="/finocchio" element={<Finocchio />} />
        <Route path="/cocomero" element={<Cocomero />} />
        <Route path="/melone" element={<Melone />} />
        <Route path="/galleria" element={<Galleria />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
    
  );
}

export default App;
