import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import './Carciofo.css';
import carciofo from '../../assets/carciofo-singolo.jpg';
import cassa from '../../assets/cassadicarciofi.jpg';
import campo from '../../assets/campo.jpg';
import mazzo from '../../assets/mazzo.jpg';

const Carciofo = () => {


  const titles = {
    1007: "Introduzione al Carciofo",
    1016: "Certificazioni",
    1017: "Conservazione",
    1018: "In conclusione"
  };

  const images = {
    1007: carciofo,
    1016: cassa,
    1017: campo,
    1018: mazzo
  };

  const articoli = {
    1007: {contenuto : 	`Il Carciofo Romanesco, gioiello dell'agricoltura laziale e fiore all'occhiello della nostra azienda, rappresenta un esempio emblematico di dedizione alla qualità e al rispetto delle tradizioni. Questo ortaggio, noto per la sua forma particolare e il suo gusto delicato, è il risultato di una coltivazione attenta e di un impegno costante nel preservare le caratteristiche uniche che lo contraddistinguono.
    Caratteristiche del Carciofo Romanesco:
    Il Carciofo Romanesco si distingue per le sue foglie avvolgenti di un verde intenso, che celano al loro interno un cuore tenero e gustoso. È apprezzato per il suo sapore dolce e leggermente nocciolato, che lo rende versatile in cucina e adatto a diverse preparazioni, dalla cottura a vapore fino alla preparazione in pinzimonio.`},
    1016 : {contenuto:`Certificazione IGP e Impegno Qualitativo:
    L'azienda ha ottenuto la certificazione IGP (Indicazione Geografica Protetta), un riconoscimento che non solo valorizza il legame con il territorio di origine, ma garantisce anche il rispetto di rigidi standard qualitativi nella produzione. Questo marchio di qualità è frutto dell'impegno dell'azienda che, attraverso generazioni, ha affinato tecniche di coltivazione sostenibili ed efficaci, preservando la biodiversità e la salute del suolo.
    `},
    1017 : {contenuto:`
    Per mantenere la freschezza del Carciofo Romanesco dopo l'acquisto, è consigliabile conservarlo in frigorifero, avvolto in un sacchetto di plastica forato o in un contenitore chiuso. Questo metodo aiuta a preservare l'umidità necessaria e a mantenere le foglie croccanti e il cuore succoso. Per un utilizzo ottimale, si consiglia di consumare i carciofi entro una settimana dall'acquisto.`},
    1018: {contenuto:`La nostra azienda continua a sostenere una filosofia di rispetto e amore per la terra, garantendo che ogni carciofo offerto ai nostri clienti non sia solo un prodotto di eccellenza, ma anche la chiara testimonianza di una storia familiare di passione e di rispetto per la natura. Attraverso il Carciofo Romanesco, non solo celebriamo la ricchezza del nostro territorio, ma ci impegniamo anche a portare avanti le tradizioni agricole del Lazio, proiettandole verso un futuro di innovazione e sostenibilità.` }


  };
  const coltura = {
    Nome: "Carciofo Romanesco",
    NomeScientifico: "Cardunculus",
    Famiglia: "Asteraceae cynara",
    BrixMedio: "Na",
    Calibro: "6-8cm (Bracciolo), 9-11cm (Cimarolo)",
    Origine: "Lazio, Italia",
    Produzione: "Gennaio-Maggio",
    Confezionamento: "Casse 40x60: 15 Cimaroli, 20 Cimaroli, 24 Cimaroli IGP, 25 Braccioli, 30 Braccioli   Mazzi:  x4,x8   Pigne IGP: x3,x5,x10"
  };

  return (
    <Container fluid className="carciofo p-0">
      <div className='photoHolder3'><h1 className='text-center'>CARCIOFO <br/>ROMANESCO IGP</h1></div>
      <div className='content-section'>
        {Object.entries(articoli).map(([id, articolo], index) => (
          <Row key={id} className="justify-content-center m-0 article-row align-items-center">
            {index % 2 === 0 ? (
              <>
                <Col md={6} className="text-content animated-left">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
                <Col md={6} className="image-content animated-right">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
              </>
            ) : (
              <>
                <Col md={6} className="image-content animated-left">
                  <div className="image-circle" style={{ backgroundImage: `url(${images[id]})` }}></div>
                </Col>
                <Col md={6} className="text-content animated-right">
                  <h2 className='h2iamo'>{titles[id]}</h2>
                  <p className='section-content'>{articolo.contenuto}</p>
                </Col>
              </>
            )}
          </Row>
        ))}
        <Row className="justify-content-center m-0">
          <Col md={12} className="table-responsive">
            <h2 className='h2iamo'>Alcuni dati</h2>
            <Table striped bordered hover className="coltura-table">
              <thead className='franco'>
                <tr>
                  <th>Nome</th>
                  <th>Nome Scientifico</th>
                  <th>Famiglia</th>
                  <th>Brix Medio</th>
                  <th>Calibro</th>
                  <th>Origine</th>
                  <th>Produzione</th>
                  <th>Confezionamento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{coltura.Nome}</td>
                  <td>{coltura.NomeScientifico}</td>
                  <td>{coltura.Famiglia}</td>
                  <td>{coltura.BrixMedio}</td>
                  <td>{coltura.Calibro}</td>
                  <td>{coltura.Origine}</td>
                  <td>{coltura.Produzione}</td>
                  <td>{coltura.Confezionamento}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Carciofo;
